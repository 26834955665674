<template>
  <div class="edit-mailing-template-page">
    <div class="mb-48 edit-mailing-template-page__header">
      <BackTitle confirm-click @click="goBack"> Template editor </BackTitle>
      <Switcher
        v-if="mailingTemplate"
        v-model="isTemplateActive"
        :label="mailingTemplate.isPaused ? 'Disabled' : 'Active'"
        :is-disabled="isLoading"
      />
    </div>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <Guard
      v-else-if="mailingTemplate"
      permission="mailingTemplates.edit"
      v-slot="{ isAvailable }"
    >
      <MailingTemplateForm
        :value="mailingTemplate"
        :is-submitting="isSubmitting"
        :is-disabled="!isAvailable"
        @submit="handleSubmit"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import MailingTemplateForm from "@/components/mailing-templates/MailingTemplateForm";
import Guard from "@/components/common/Guard";
export default {
  name: "EditMailingTemplatePage",
  components: { Guard, MailingTemplateForm, BackTitle },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      mailingTemplate: (state) => state.mailingTemplates.editedItem,
    }),
    isTemplateActive: {
      get() {
        return !this.mailingTemplate?.isPaused;
      },
      set() {
        this.handlePause();
      },
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchMailingTemplateById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchMailingTemplateById: "mailingTemplates/fetchMailingTemplateById",
      updateMailingTemplate: "mailingTemplates/updateMailingTemplate",
    }),
    goBack() {
      this.$router.push({
        name: "MailingTemplates",
      });
    },
    async handleSubmit({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateMailingTemplate({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    async handlePause() {
      try {
        this.isLoading = true;
        await this.updateMailingTemplate({
          id: this.mailingTemplate?.id,
          data: { isPaused: !this.mailingTemplate?.isPaused },
        });
        await this.fetchMailingTemplateById(this.mailingTemplate.id);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-mailing-template-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
